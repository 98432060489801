var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "progressbar-container",
      class: { "progressbar-container--hover": _vm.hover },
      style: _vm.progressbarContainerStyle(),
    },
    [
      _c("div", { staticClass: "scale", style: _vm.scaleStyle() }),
      _c("div", { staticClass: "progress", style: _vm.progressStyle() }),
      _vm.text
        ? _c("div", { staticClass: "text" }, [
            _vm._v(" " + _vm._s(_vm.text) + " "),
          ])
        : _vm._e(),
      _vm.text2
        ? _c("div", { staticClass: "text2" }, [
            _vm._v(" " + _vm._s(_vm.text2) + " "),
          ])
        : _vm._e(),
      _vm.showPercent
        ? _c("div", { staticClass: "percent" }, [
            _vm._v(" " + _vm._s(_vm.getPercentageTitle) + " "),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }