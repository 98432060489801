<template>
  <div
    class="progressbar-container"
    :class="{ 'progressbar-container--hover': hover }"
    :style="progressbarContainerStyle()"
  >
    <div class="scale" :style="scaleStyle()" />
    <div class="progress" :style="progressStyle()" />
    <div v-if="text" class="text">
      {{ text }}
    </div>
    <div v-if="text2" class="text2">
      {{ text2 }}
    </div>
    <div v-if="showPercent" class="percent">
      {{ getPercentageTitle }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Progressbar',
  props: {
    progressColor: {
      type: String,
      default: '#5DAF34',
    },
    text: {
      type: String,
      default: '',
    },
    text2: {
      type: String,
      default: '',
    },
    progressHeight: {
      type: String,
      default: '',
    },
    scaleHeight: {
      type: String,
      default: '',
    },
    percent: {
      type: Number,
      default: 0,
    },
    bgcScale: {
      type: String,
      default: '',
    },
    bgZIndex: {
      type: Number,
      default: 1,
    },
    offsetProgress: {
      type: Number,
      default: null,
    },
    hover: {
      type: Boolean,
      default: true,
    },
    borderRadius: {
      type: String,
      default: '5px',
    },
    showPercent: {
      type: Boolean,
      default: false,
    },
    fraction: {
      type: Object,
      default: () => ({}),
    },
    percentOrFraction: {
      type: String,
      default: 'percent',
    },
  },
  computed: {
    getPercentageTitle() {
      return this.percentOrFraction === 'percent'
        ? `${this.percent.toFixed(1)}%`
        : `${this.fraction.current}/${this.fraction.total}`
    },
    getPercentage() {
      return this.percentOrFraction === 'percent'
        ? this.percent.toFixed(1)
        : this.getPercentageFraction
    },
    checkPercentage() {
      return this.percentOrFraction === 'percent'
        ? this.percent > 100
        : this.fraction?.current / this.fraction?.total >= 1
    },
    getPercentageFraction() {
      if (this.fraction?.current / this.fraction?.total >= 1) {
        return '100'
      }

      return ((this.fraction?.current / this.fraction?.total) * 100).toFixed(1)
    },
    checkProgressColor() {
      return this.checkPercentage ? 'red' : this.progressColor
    },
  },
  methods: {
    progressStyle() {
      let arr = []

      this.progressColor &&
        arr.push({ 'background-color': this.checkProgressColor })
      this.progressHeight && arr.push({ height: this.progressHeight + 'px' })
      arr.push({ width: this.getPercentage + '%' })
      this.offsetProgress && arr.push({ left: this.offsetProgress + 'px' })
      this.offsetProgress &&
        arr.push({ 'max-width': `calc(100% - ${this.offsetProgress * 2}px)` })
      this.borderRadius && arr.push({ borderRadius: this.borderRadius })

      return arr
    },
    progressbarContainerStyle() {
      let arr = []
      const maxHeight = Math.max(
        Number(this.progressHeight),
        Number(this.scaleHeight),
      )

      this.progressHeight && arr.push({ height: maxHeight + 'px' })
      this.borderRadius && arr.push({ borderRadius: this.borderRadius })

      return arr
    },
    scaleStyle() {
      let arr = []

      this.scaleHeight && arr.push({ height: this.scaleHeight + 'px' })
      this.bgcScale && arr.push({ 'background-color': this.bgcScale })
      this.bgZIndex && arr.push({ 'z-index': this.bgZIndex })
      this.borderRadius && arr.push({ borderRadius: this.borderRadius })

      return arr
    },
  },
}
</script>

<style lang="sass">
$height: 38px
@mixin text
  position: absolute
  z-index: 3
  font-size: 13px
  text-transform: uppercase
  font-weight: 600
  color: #fff
  line-height: $height
.progressbar-container
  position: relative
  display: flex
  justify-content: flex-start
  align-items: center
  overflow: hidden
  width: 100%
  height: $height
  background-color: #fff
  text-align: center
  &--hover
    &:hover
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, .6)
  & .scale
    position: absolute
    height: $height
    width: 100%
    z-index: 1
    background-color: #eeeeee
  & .progress
    position: absolute
    z-index: 2
    height: $height
    width: 50%
    background-color: #51eae1
    transition: 1s
  & .text
    @include text
    left: 2%
    +to(500px)
      display: none
  & .text2
    @include text
    left: 18%
    +to(900px)
      left: 5%
  .percent
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    z-index: 3

  .text ~ .percent,
  .text2 ~ .percent
    font-size: 13px
    text-transform: uppercase
    font-weight: 600
    color: #fff
    +to(900px)
      left: auto
      right: 10px
      transform: translate(0, -50%)
  .text ~ .text2
    left: 100px
    +to(500px)
      left: 5%
</style>
